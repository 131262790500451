import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends Base implements OnInit {
  contactUs: any = [];
  subject = "";
  message = "";
  mailText = "";

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getContactUs().subscribe(response => {
      this.contactUs = response.Contattaci;
    });
  }


  sendMail(){
    // this.mailText = "mailto:mail@villamannaroncadelli.it?subject=" + this.subject + "&body="+this.message;
    this.mailText = "mailto:mail@villamannaroncadelli.it?subject=" 
      + (<HTMLInputElement>document.getElementById("subject")).value 
      + "&body="+(<HTMLInputElement>document.getElementById("message")).value;

    window.location.href = this.mailText;
  }

}
