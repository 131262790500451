import { Component, OnInit } from '@angular/core';
import { Base } from '../base';
import { HomeModel } from '../models/home-model';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends Base implements OnInit {
  // home = new HomeModel();
  home: any = [];

  constructor(private dataService: DataService) {
    super();
  }

  ngOnInit() {
    this.dataService.getHome().subscribe(response => {
      this.home = response.Home;
    });
  }

}
